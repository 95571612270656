





































import Vue from 'vue';
import ContactResourceViewModel from '../../../../services/viewModel/resource/ContactResourceViewModel';
import CorrespondenceContactResourceViewModel from '../../../../services/viewModel/resource/CorrespondenceContactResourceViewModel';
import MembershipResourceViewModel from '../../../../services/viewModel/resource/MembershipResourceViewModel';
import RequestSummaryResourceViewModel from '@/src/services/viewModel/resource/RequestSummaryResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    isLoading: false,
    requestError: undefined,
    membershipData: undefined as MembershipResourceViewModel | undefined,
  }),
  computed: {
    membership(): MembershipResourceViewModel {
      return this.membershipData ?? {} as MembershipResourceViewModel;
    },
    memberContact(): ContactResourceViewModel {
      return this.membership.memberContact ?? {};
    },
    billingContact(): CorrespondenceContactResourceViewModel {
      return this.membership.billingContact ?? {};
    },
    businessUnitContactSummary(): RequestSummaryResourceViewModel {
      if (this.membershipData?.businessUnitContact) {
        return this.membershipData?.businessUnitContactSummary ?? {} as RequestSummaryResourceViewModel;
      }
      return {} as RequestSummaryResourceViewModel;
    },
    regionContactSummary(): RequestSummaryResourceViewModel {
      if (this.membershipData?.regionContact) {
        return this.membershipData?.regionContactSummary ?? {} as RequestSummaryResourceViewModel;
      }
      return {} as RequestSummaryResourceViewModel;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    try {
      this.membershipData = await this.$service.api.memberships.getMyMembership();
    } catch (error: any) {
      this.requestError = error;
    }
    this.isLoading = false;
  },
  methods: {
    gotoChangeMemberAddress() {
      this.$router.push('/gs/memberPortal/member-data/change-member-address');
    },
  },
});
